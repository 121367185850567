<template>
<div id="corona">
  <div class="hidden-sm-and-down">
    <PageHeader/>
  </div>
  <div class="hidden-md-and-up">
    <m-top/>
  </div>

  <div class="hidden-xs-only">
    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15"> 
        <div style="width: 100%; max-width: 1284px; margin: 0 auto;">
          <div class="side-box" style="margin-bottom: 30px;">
              <div class="title">コロナ予防接種</div>
          </div>
          <div class="separator">    
            <div class="content1">
              <div class="side-text">
                <div class="title1">新型コロナワクチン予防接種についての説明書</div>
                <div class="content_text">
                  <div class="text1">新型コロナワクチンの予防接種は、令和6年4月以降は、65歳以上の方および60～64歳で重症化リスクの高い方を対象とした定期接種に変わります。当院では任意接種のみの方について自費診療での接種を受け付けております。</div>
                  <div class="text1">自治体で提供される接種券での対応は当院ではお受けしておりません。</div>
                </div>
              </div>
            </div>         

            <div class="content1">
              <div class="seperate_line"></div>
              <div class="side-text">
                <div class="title1">ワクチンについて</div>
                <div class="content_text">
                  <div class="text1">接種ワクチン：コミナティRTU筋注1人用 <br>
                    接  種  対  象：12 歳以上<br>
                    摂    取    量：1 回 0.3mL</div>
                </div>
              </div>
            </div>

            <div class="content1">
              <div class="side-text">
                <div class="text1">接  種  時  期<br>
                  ・前回の SARS-CoV-2 ワクチンの接種から少なくとも3ヶ月経過した後に接種することができる<br>
                  ・過去に SARS-CoV-2 ワクチンの接種歴のない人に関しては，１回目の接種から4週間の間隔をおいて2回目の接種が可能
                </div>
              </div>
            </div>

            <div class="content1">
              <div class="side-text">
                <div class="text1" style="font-weight:bold">価格：16,500 円(税別)</div>
              </div>
            </div>

            <div class="content1">
              <div class="seperate_line"></div>
              <div class="side-text">
                <div class="title1">予約方法</div>
                <div class="content_text">
                  <div class="text1">接種をご希望される場合は，事前に当院までご連絡ください。</div>
                  <div class="text1">お電話でご予約を受け付け後，ワクチンを発注いたします。</div>
                  <div class="tel">
                    <div><img src="../../assets/icon/icon_tel_hover_28x28@2x.png" alt="" width="28" height="28"></div>
                  <div class="tel-number">03-6260-6008</div>
              </div>
                </div>
              </div>
            </div>

            <div class="content1">
              <div class="seperate_line"></div>
              <div class="side-text">
                <div class="title1">新型コロナワクチンの接種(予防接種)を受けることができない人</div>
                <div class="content_text">
                  <div class="text1">下記に該当する方は本ワクチンを接種できません。</div>
                  <div class="text1">該当すると思われる場合、必ず接種前の診察時に医師へ伝えてください。</div>
                  <div class="text1">
                    ・明らかに発熱している人(※1)<br>
                    ・重い急性疾患にかかっている人<br>
                    ・本ワクチンの成分に対し重度の過敏症(※2)の既往歴のある人<br>
                    ・上記以外で、予防接種を受けることが不適当な状態にある人<br>
                  </div>
                </div>
              </div>
            </div>

            <div class="content1">
              <div class="side-text">
                <div class="text1">（※1）明らかな発熱とは通常 37.5°C 以上を指します。ただし、37.5°C を下回る場合も平時の体温を鑑みて発熱と判断される場合はこの限りではありません。</div>
                <div class="text1">（※2）アナフィラキシーや、全身性の皮膚・粘膜症状、喘鳴、呼吸困難、頻脈、血圧低下等、アナフィラキシーを疑わせる複数の症状。</div>
              </div>
            </div> 

            <div class="content1">
              <div class="seperate_line"></div>
              <div class="side-text">
                <div class="title1">予防接種を受けるにあたり注意が必要な人</div>
                <div class="content_text">
                  <div class="text1">下記にあてはまる方は本ワクチンの接種について、注意が必要です。該当すると思われる場合は、必ず接種前の診察時に医師へ伝えてください。</div>
                  <div class="text1">
                    ・抗凝固療法を受けている人、血小板減少症または凝固障害のある人<br>
                    ・過去に免疫不全の診断を受けた人、近親者に先天性免疫不全症の方がいる人<br>
                    ・心臓、腎臓、肝臓、血液疾患や発育障害などの基礎疾患のある人<br>
                    ・過去に予防接種を受けて、接種後2日以内に発熱や全身性の発疹などのアレルギーが疑われる症状がでた人<br>
                    ・過去にけいれんを起こしたことがある人<br>
                    ・本ワクチンの成分に対して、アレルギーが起こるおそれがある人
                  </div>
                </div>
              </div>
            </div>
            
            <div class="side-text">
              <div class="content1">
                <div class="text1">
                  妊娠中、又は妊娠している可能性がある人、授乳されている人は、接種前の診察時に必ず医師へ伝えてください。
                </div>
              </div>
              
              <div class="content1">
                <div class="text1">
                  本剤には、これまでのワクチンでは使用されたことのない添加剤が含まれています。過去に、薬剤で過敏症やアレルギーを起こしたことのある人は、接種前の診察時に必ず医師へ伝えてください。
                </div>             
              </div>
            </div>

            <div class="content1">
              <div class="seperate_line"></div>
              <div class="side-text">
                <div class="title1">副反応について</div>
              </div>
            </div>

            <div class="side-text">
              <div class="content1">
                <div class="text1">
                  ◼️注射した部分の痛み、頭痛、関節や筋肉の痛み、疲労、寒気、発熱等が現れることがあります。また、重大な副反応として、ショックやアナフィラキシーが現れることもあります。なお、本ワクチンは、新しい種類のワクチンのため、これまでに明らかになっていない症状が出る可能性があります。接種後に気になる症状があった場合は、接種医あるいはかかりつけ医に相談してください。
                </div>             
              </div>

              <div class="content1">
                <div class="text1">
                  ◼️ワクチン接種後にごく稀に心筋炎や心膜炎を疑う事例が報告されています。接種後数日以内に胸の痛みや動悸、息切れ、むくみ等の症状が現れたら、速やかに医療機関を受診してください。
                </div>             
              </div>

              <div class="content1">
                <div class="text1">
                  ◼️ワクチン接種後にギラン・バレー症候群が報告されています。接種後、手足の力が入りにくい、しびれ等の症状が現れたら、速やかに医療機関を受診してください。
                </div>             
              </div>
            </div>

            <div class="content1">
              <div class="seperate_line"></div>
              <div class="side-text">
                <div class="title1">予防接種を受けた後の注意点</div>
              </div>
            </div>

            <div class="side-text">
              <div class="content1">
                <div class="text1">
                  ◼️予防接種を受けた後、15 分以上（過去にアナフィラキシーを含む重いアレルギー症状を起こしたことがある方や、気分が悪くなったり、失神等を起こしたことがある方は 30 分以上）、接種を受けた施設でお待ちいただき、体調に異常を感じた場合には、速やかに医師へ連絡してください。<br>
                  （急に起こる副反応に対応できます）
                </div>             
              </div>

              <div class="content1">
                <div class="text1">
                  ◼️注射した部分は清潔に保つようにし、接種当日の入浴は問題ありませんが、注射した部分はこすらないようにしてください。また、接種後に体調が悪い時は無理をせず、入浴は控える等、様子を見るようにしてください。
                </div>             
              </div>

              <div class="content1">
                <div class="text1">
                  ◼️通常の生活は問題ありませんが、当日の激しい運動や過度の飲酒等は控えてください。
                </div>             
              </div>
            </div>

          </div>
        </div>
      </el-col>
    </el-row>
    <PageFooter/>
  </div>

  <div class="hidden-sm-and-up">
    <div style="width: 100%; max-width: 1284px; margin: 0 auto;">
      <div class="side-box" style="margin-bottom: 30px;">
          <div class="title">コロナ予防接種</div>
      </div>
      <div class="separator">    
        <div class="content1">
          <div class="m_side-text">
            <div class="title1">新型コロナワクチン予防接種についての説明書</div>
            <div class="content_text">
              <div class="text1">新型コロナワクチンの予防接種は、令和6年4月以降は、65歳以上の方および60～64歳で重症化リスクの高い方を対象とした定期接種に変わります。当院では任意接種のみの方について自費診療での接種を受け付けております。</div>
              <div class="text1">自治体で提供される接種券での対応は当院ではお受けしておりません。</div>
            </div>
          </div>
        </div>         

        <div class="content1">
          <div class="seperate_line"></div>
          <div class="m_side-text">
            <div class="title1">ワクチンについて</div>
            <div class="content_text">
              <div class="text1">接種ワクチン：コミナティRTU筋注1人用 <br>
                接  種  対  象：12 歳以上<br>
                摂    取    量：1 回 0.3mL</div>
            </div>
          </div>
        </div>

        <div class="content1">
          <div class="m_side-text">
            <div class="text1">接  種  時  期<br>
              ・前回の SARS-CoV-2 ワクチンの接種から少なくとも3ヶ月経過した後に接種することができる<br>
              ・過去に SARS-CoV-2 ワクチンの接種歴のない人に関しては，１回目の接種から4週間の間隔をおいて2回目の接種が可能
            </div>
          </div>
        </div>

        <div class="content1">
          <div class="m_side-text">
            <div class="text1" style="font-weight:bold">価格：16,500 円(税別)</div>
          </div>
        </div>

        <div class="content1">
          <div class="seperate_line"></div>
          <div class="m_side-text">
            <div class="title1">予約方法</div>
            <div class="content_text">
              <div class="text1">接種をご希望される場合は，事前に当院までご連絡ください。</div>
              <div class="text1">お電話でご予約を受け付け後，ワクチンを発注いたします。</div>
              <div class="tel" onclick="window.location.href='tel:0362606008'">
                    <div><img src="../../assets/icon/icon_tel_hover_28x28@2x.png" alt="" width="28" height="28"></div>
                  <div class="tel-number">03-6260-6008</div>
              </div>

            </div>
          </div>
        </div>

        <div class="content1">
          <div class="seperate_line"></div>
          <div class="m_side-text">
            <div class="title1">新型コロナワクチンの接種(予防接種)を受けることができない人</div>
            <div class="content_text">
              <div class="text1">下記に該当する方は本ワクチンを接種できません。</div>
              <div class="text1">該当すると思われる場合、必ず接種前の診察時に医師へ伝えてください。</div>
              <div class="text1">
                ・明らかに発熱している人(※1)<br>
                ・重い急性疾患にかかっている人<br>
                ・本ワクチンの成分に対し重度の過敏症(※2)の既往歴のある人<br>
                ・上記以外で、予防接種を受けることが不適当な状態にある人<br>
              </div>
            </div>
          </div>
        </div>

        <div class="content1">
          <div class="m_side-text">
            <div class="text1">（※1）明らかな発熱とは通常 37.5°C 以上を指します。ただし、37.5°C を下回る場合も平時の体温を鑑みて発熱と判断される場合はこの限りではありません。</div>
            <div class="text1">（※2）アナフィラキシーや、全身性の皮膚・粘膜症状、喘鳴、呼吸困難、頻脈、血圧低下等、アナフィラキシーを疑わせる複数の症状。</div>
          </div>
        </div> 

        <div class="content1">
          <div class="seperate_line"></div>
          <div class="m_side-text">
            <div class="title1">予防接種を受けるにあたり注意が必要な人</div>
            <div class="content_text">
              <div class="text1">下記にあてはまる方は本ワクチンの接種について、注意が必要です。該当すると思われる場合は、必ず接種前の診察時に医師へ伝えてください。</div>
              <div class="text1">
                ・抗凝固療法を受けている人、血小板減少症または凝固障害のある人<br>
                ・過去に免疫不全の診断を受けた人、近親者に先天性免疫不全症の方がいる人<br>
                ・心臓、腎臓、肝臓、血液疾患や発育障害などの基礎疾患のある人<br>
                ・過去に予防接種を受けて、接種後2日以内に発熱や全身性の発疹などのアレルギーが疑われる症状がでた人<br>
                ・過去にけいれんを起こしたことがある人<br>
                ・本ワクチンの成分に対して、アレルギーが起こるおそれがある人
              </div>
            </div>
          </div>
        </div>
        
        <div class="m_side-text">
          <div class="content1">
            <div class="text1">
              妊娠中、又は妊娠している可能性がある人、授乳されている人は、接種前の診察時に必ず医師へ伝えてください。
            </div>
          </div>
          
          <div class="content1">
            <div class="text1">
              本剤には、これまでのワクチンでは使用されたことのない添加剤が含まれています。過去に、薬剤で過敏症やアレルギーを起こしたことのある人は、接種前の診察時に必ず医師へ伝えてください。
            </div>             
          </div>
        </div>

        <div class="content1">
          <div class="seperate_line"></div>
          <div class="m_side-text">
            <div class="title1">副反応について</div>
          </div>
        </div>

        <div class="m_side-text">
          <div class="content1">
            <div class="text1">
              ◼️注射した部分の痛み、頭痛、関節や筋肉の痛み、疲労、寒気、発熱等が現れることがあります。また、重大な副反応として、ショックやアナフィラキシーが現れることもあります。なお、本ワクチンは、新しい種類のワクチンのため、これまでに明らかになっていない症状が出る可能性があります。接種後に気になる症状があった場合は、接種医あるいはかかりつけ医に相談してください。
            </div>             
          </div>

          <div class="content1">
            <div class="text1">
              ◼️ワクチン接種後にごく稀に心筋炎や心膜炎を疑う事例が報告されています。接種後数日以内に胸の痛みや動悸、息切れ、むくみ等の症状が現れたら、速やかに医療機関を受診してください。
            </div>             
          </div>

          <div class="content1">
            <div class="text1">
              ◼️ワクチン接種後にギラン・バレー症候群が報告されています。接種後、手足の力が入りにくい、しびれ等の症状が現れたら、速やかに医療機関を受診してください。
            </div>             
          </div>
        </div>

        <div class="content1">
          <div class="seperate_line"></div>
          <div class="m_side-text">
            <div class="title1">予防接種を受けた後の注意点</div>
          </div>
        </div>

        <div class="m_side-text">
          <div class="content1">
            <div class="text1">
              ◼️予防接種を受けた後、15 分以上（過去にアナフィラキシーを含む重いアレルギー症状を起こしたことがある方や、気分が悪くなったり、失神等を起こしたことがある方は 30 分以上）、接種を受けた施設でお待ちいただき、体調に異常を感じた場合には、速やかに医師へ連絡してください。<br>
              （急に起こる副反応に対応できます）
            </div>             
          </div>

          <div class="content1">
            <div class="text1">
              ◼️注射した部分は清潔に保つようにし、接種当日の入浴は問題ありませんが、注射した部分はこすらないようにしてください。また、接種後に体調が悪い時は無理をせず、入浴は控える等、様子を見るようにしてください。
            </div>             
          </div>

          <div class="content1">
            <div class="text1">
              ◼️通常の生活は問題ありませんが、当日の激しい運動や過度の飲酒等は控えてください。
            </div>             
          </div>
        </div>

      </div>
    </div>


    <m-departs/>
    <m-footer/>
  </div>
</div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import DepartmentAside from "@/components/DepartmentAside";
import MTop from "../../components/m/MTop";
import MFooter from "../../components/m/MFooter";
import MDeparts from "../../components/m/MDeparts";
export default {
  name: "Corona",
  components: {MDeparts, MFooter, MTop, DepartmentAside, PageFooter, PageHeader}
}
</script>

<style scoped>
/* PC */
.hidden-sm-and-down .img {
  margin-top: 30px;
}
.detail1 {
  margin: 40px 20px;
}
.content1 {
      margin-top: 40px;
    }

    .content_text{
        margin-top: 18px;
    }
.title1 {
      color: #62b6e2;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .text1 {
      color: #535353;
      line-height: 1.5;
      margin-bottom: 15px;
    }
    
.other {
  color: #535353;
  font-size: 14px;
  line-height: 1.5;
}
.seperate_line{
      height:5px;
      border-top-color: #62b6e2;
      border-top-width: 2px;
      border-top-style: solid;
      margin: 0 auto;
      width: 100%;
    }

/* SP */
.m-main {
  background-color: #FFFFFF;
  border-top: 2px solid #62b6e2;
  border-bottom: 2px solid #62b6e2;
  padding: 2rem 0;
}
.m-title {
  color: #535353;
  font-size: 24px;
  text-align: center;
  margin: 2rem 0;
}
.m-detail {
  background-color: #FFFFFF;
  padding: 0 1rem;
}
.m-subtitle {
  color: #71cdff;
  font-size: 18px;
  padding: 2rem 0 1rem;
}
.m-content {
  line-height: 24px;
  color: #535353;
}

.title {
      font-size: 30px;
      letter-spacing: 5px;
      padding-top: 20px;
      padding-left: 20px;
    }
    
    .separator {
      color: #62b6e2;
      width: 100%;
      margin: 0 auto;
      border-top: 4px solid;
      border-bottom: 4px solid;
    }
    .side-img{
    height: auto;
    width:auto;
    vertical-align:middle;
    margin-left: 30px;
    margin-right: 30px;
    }
  
    .side-text{
    height: fit-content;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: auto;
    }
    .m_side-text{
    height: fit-content;
    margin-left: 7%;
    margin-right: 7%;
    width: 86%;
    margin-bottom: auto;
    }
    
    
    .side-box{
      display: inline-flex;
      justify-content: space-between;
      align-items:center;
      vertical-align: top;
      height: auto;
    }
  
    .side-box-img{
      display: inline-flex;
      justify-content: space-between;
      align-items:top;
      vertical-align: top;
      height: auto;
    }
  
    .fit_img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    .side-line-highlight{
      background-color: #CBB8B2;
      width:6px;
      height: 25px;
      margin-bottom: 10px;
      margin-right: 10px;
    }

    .tel {
      align-items: center;
      display: flex;
      margin-top: 14px;
    }
    .tel .tel-number {
      font-size: 24px;
      color: #535353;
      font-weight: bold;
      margin-left: 14px;
    }
</style>
